angular.module('app.filters', []).filter('range', function() {
  return function(input, total) {
    var i, j, ref;
    total = parseInt(total);
    for (i = j = 0, ref = total; (0 <= ref ? j <= ref : j >= ref); i = 0 <= ref ? ++j : --j) {
      input.push(i);
    }
    return input;
  };
}).filter('truncate', function() {
  return function(string, length, after = '...') {
    string = string + '';
    if (!(string.length > length)) {
      return string;
    }
    return string.substring(0, length).replace(/\s+$/, '') + after;
  };
}).filter('booth', function() {
  return function(string, length = 1) {
    if (!string) {
      return;
    }
    return string.substring(0, length).toLowerCase();
  };
}).filter('nl2br', function() {
  return function(string) {
    if (typeof string !== 'string') {
      return '';
    }
    return string.replace(/\n/g, '<br />');
  };
}).filter('currency123', [
  '$filter',
  function($filter) {
    return function(amount,
  symbol) {
      symbol = '€';
      return ($filter('number')(amount,
  2)) + " " + symbol;
    };
  }
]).filter('categories', function() {
  return function(array) {
    var c;
    if (!(array != null ? array.length : void 0)) {
      return "";
    }
    c = array.length > 3 ? "..." : "";
    return array.map(function(a) {
      return a;
    }).sort().slice(0, 3).join(", ").concat(" ", c);
  };
}).filter('time', [
  '$filter',
  function($filter) {
    return function(input,
  arg) {
      var date,
  parts;
      if (input == null) {
        return;
      }
      parts = input.split(':');
      date = new Date(0,
  0,
  0,
  parts[0],
  parts[1],
  parts[2]);
      return $filter('date')(date,
  arg || 'HH:mm');
    };
  }
]).filter('numberGer', function() {
  return function(string) {
    var rgx, x, x1, x2;
    string = parseFloat(string).toFixed(2).toString();
    string = string.replace('.', ',');
    string += '';
    x = string.split(',');
    x1 = x[0];
    if (x.length > 1) {
      x2 = ',' + x[1];
    } else {
      x2 = '';
    }
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    string = x1 + x2;
    return string;
  };
});
