angular.module('app.filters', [])  
  .filter 'range', () ->
    return (input, total) ->
      total = parseInt(total)
      for i in [0..total]
        input.push(i)
      return input

  .filter 'truncate', ->
    (string, length, after = '...') ->
      string = string + ''
      return string unless string.length > length
      string.substring(0, length).replace(/\s+$/, '') + after

  .filter 'booth', ->
    (string, length = 1) ->
      return unless string
      string.substring(0, length).toLowerCase()

  .filter 'nl2br', () ->
    return (string) ->
      if (typeof string != 'string') 
        return ''
      
      return string.replace(/\n/g, '<br />')
  
  .filter 'currency123', ['$filter', ($filter) ->
    return (amount, symbol) ->
      symbol = '€';

      return ($filter('number')(amount, 2)) + " " + symbol
  ]

  .filter 'categories', () ->
    return (array) ->
      return "" unless array?.length
      c = if array.length > 3 then "..." else ""
      return array.map((a) -> return a).sort().slice(0,3).join(", ").concat(" ", c)

  .filter 'time', ['$filter', ($filter) ->
    return (input, arg) ->
      return unless input?
      parts = input.split(':')
      date = new Date(0, 0, 0, parts[0], parts[1], parts[2]);
      return $filter('date')(date, arg || 'HH:mm')
  ]


  .filter 'numberGer', ->
    (string) ->
      string = parseFloat(string).toFixed(2).toString()
      string = string.replace('.', ',')

      string += ''
      x = string.split(',')
      x1 = x[0]
      if x.length > 1
        x2 = ',' + x[1]
      else
        x2 = ''
      rgx = /(\d+)(\d{3})/
      while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + '.' + '$2')
      string = x1 + x2

      return string

